//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        successUrl: {
            type: String,
            default: '/settle/bill/list',
        },
    },
    data: function () {
        return {
            form: {
                billName: '',
                remark: '',
            },
        };
    },
    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit() {
            this.$post(
                `${this.$store.getters.indexUrl}/settle/settBill/add`,
                {
                    ...this.form,
                    settleOrderList: this.data.map((item) => {
                        return {
                            orderId: item.orderId,
                        };
                    }),
                },
                (data) => {
                    this.cancle();
                    ShowMsg('申请成功', 'success');

                    this.goto(this.successUrl);
                },
                true
            );
        },
    },
    mounted() {},
};

export const data = [
    {
        value: '未开始',
        key: 'WKS',
        type: 'info',
    },
    {
        value: '结算中',
        key: 'JSZ',
        type: 'primary',
    },
    {
        value: '结算完成',
        key: 'JSWC',
        type: 'success',
    },
];

export function getItem(key) {
    return data.filter((item) => item.key === key)[0] || {};
}

export const dataAll = [
    {
        value: '全部',
        key: '',
        type: '',
    },
    ...data,
]
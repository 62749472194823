//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import COMMON_METHODS from '@mixins/common-methods.js';

import {
    dataAll as settleProgressData,
    getItem as getSettleProgressItem,
} from './data/settleProgress.js';

import FormBill from './form-bill.vue';

export default {
    mixins: [COMMON_METHODS],
    props: [],
    components: {
        FormBill,
    },
    data: function () {
        return {
            pgData: {
                orderId: '',
                tradeStartTime: '',
                tradeEndTime: '',
                bidOpenStartTime: '',
                bidOpenEndTime: '',
                productId: '',
                projectName: '',
                tendererName: '',
                tenderAgencyName: '',
                settleStatus: '0',
                bizType: '',
                settleProgress: '',

                title: '',
                starttime: '',
                endtime: '',
                sortname: 'addtime',
                sortorder: 'desc',
                pagesize: 20,
                pageindex: '1',
            },
            tableData: [],
            tableHeight: window.innerHeight - 245,
            //合作单位
            pgExtra: {},

            multipleSelection: [],

            dialogVisible: false,
            dialogData: [],

            settleProgressData,
        };
    },
    computed: {
        opentime: {
            get: function () {
                var startTime = this.pgData.bidOpenStartTime || '';
                var endtime = this.pgData.bidOpenEndTime || '';
                if (startTime && endtime) {
                    return [startTime, endtime];
                } else {
                    return [];
                }
            },
            set: function (val) {
                if (!val) {
                    this.pgData.bidOpenStartTime = '';
                    this.pgData.bidOpenEndTime = '';
                } else {
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.bidOpenStartTime = startTime;
                    this.pgData.bidOpenEndTime = endtime;
                }
            },
        },
        ordertime: {
            get: function () {
                var startTime = this.pgData.tradeStartTime || '';
                var endtime = this.pgData.tradeEndTime || '';
                if (startTime && endtime) {
                    return [startTime, endtime];
                } else {
                    return [];
                }
            },
            set: function (val) {
                if (!val) {
                    this.pgData.tradeStartTime = '';
                    this.pgData.tradeEndTime = '';
                } else {
                    // var startTime = val[0] || '';
                    // var endtime = val[1] || '';
                    var startTime = val[0] + ' ' + '00:00:00';
                    var endtime = val[1] + ' ' + '23:59:59';
                    this.pgData.tradeStartTime = startTime;
                    this.pgData.tradeEndTime = endtime;
                }
            },
        },
        totalList: function () {
            return [
                {
                    label: '订单合计金额：',
                    num: this.pgExtra.dingDanHeJiJinE,
                    util: '元',
                },
                {
                    label: '项目数量：',
                    num: this.pgExtra.xiangMuShuLiang,
                    util: '个',
                },
            ];
        },
    },
    watch: {},

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },
        //详情
        listDetail: function (row) {
            if (this.getQuery('token')) {
                this.goto({
                    path: './detail',
                    query: {
                        orderId: row.orderId,
                        token: this.getQuery('token'),
                    },
                });
            } else {
                this.goto({
                    path: './detail',
                    query: {
                        orderId: row.orderId,
                    },
                });
            }
        },
        //导出
        exportList: function () {
            var token = this.getQuery('token') || this.getGetters('user').token;

            var search = toSearch({
                ...this.pgData,
                isExport: true,
                token,
            });

            window.open(
                `${this.$store.getters.indexUrl}/settle/settOrder/listPage${search}`
            );
        },
        afterQuery: function (data, res) {
            this.tableHeight = window.innerHeight - 245;
        },

        tableSelectable(row) {
            var { canSelect } = row;

            return canSelect;
        },
        dialogCloseHandler() {
            this.dialogVisible = false;
            this.multipleSelection = [];
            this.$refs.page.reload();
        },

        billHandler() {
            if (!this.multipleSelection.length) {
                return;
            }

            this.dialogData = this.multipleSelection;
            this.dialogVisible = true;
        },

        getSettleProgressItem,
    },
    mounted() {},
};

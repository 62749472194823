//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import COMMON_METHODS from '@mixins/common-methods.js';

export default {
    mixins: [COMMON_METHODS],
    components: {},
    props: {},
    data() {
        return {
            detailData: {},
            tableData: [],
            settleList: [],

            listStatus: [
                { value: '待确认', key: '1', type: 'info' },
                { value: '待开票', key: '2', type: 'warning' },
                { value: '待结算', key: '3', type: 'primary' },
                { value: '已结算', key: '4', type: 'success' },
            ],
        };
    },
    computed: {
        orderId: function () {
            return this.getQuery('orderId');
        },
    },
    watch: {},
    methods: {
        getDetail: function () {
            this.$get(
                `${this.$store.getters.indexUrl}/settle/settOrder/getInfo`,
                {
                    orderId: this.orderId,
                },
                function (data) {
                    this.detailData = data;
                    this.tableData = data.settleBillList;
                    this.settleList = data.settleOrderRateList;
                }
            );
        },
        //返回
        goBack: function () {
            this.$router.go(-1);
        },
    },
    mounted: function () {
        this.getDetail();
    },
};
